<template>
  <div class="main" id="home">
    <div class="container">
      <h2>
        <span
          class="journey wow animate__animated animate__fadeInLeft"
          data-wow-delay="0.1s"
          >Excellence in Home Health Care</span
        >
      </h2>
      <h3
        class="subtitle wow animate__animated animate__fadeInUp"
        data-wow-delay="0.3s"
      >
        Golden Palms Home Health Care, Inc.
      </h3>
      <p
        class="subtext wow animate__animated animate__fadeInUp"
        data-wow-delay="0.5s"
      >
        At Golden Palms Home Health Care, Inc., we are dedicated to enhancing
        lives through compassionate care. Our team of professionals is committed
        to providing top-quality healthcare, ensuring patient satisfaction
        around the clock.
      </p>
      <a
        href="#about"
        class="cta-button wow animate__animated animate__fadeInUp"
        data-wow-delay=".7s"
      >
        Learn More
      </a>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import "animate.css";

export default {
  name: "MainSection",
  mounted() {
    new WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.main {
  background: url("@/assets/Home/home.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: left;

  .container {
    padding-left: 20px;
  }

  h2 {
    max-width: 750px;
    color: #000;
    font-size: 112px;
    text-align: left;
    letter-spacing: -0.02em;
    line-height: 0.8;

    .journey {
      font-size: 90px;
      font-weight: lighter;
    }

    @media (max-width: 1200px) {
      font-size: 90px;
      .journey {
        font-size: 72px;
      }
    }

    @media (max-width: 992px) {
      line-height: 0.5;
      font-size: 90px;
      .journey {
        font-size: 52px;
      }
    }
  }

  .subtitle {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
  }

  .subtext {
    color: #000;
    font-size: 18px;
    max-width: 800px;
    font-weight: 100;
    margin-bottom: 30px;
  }

  .cta-button {
    margin-top: 40px;
    padding: 15px 30px;
    font-size: 18px;
    color: #fff;
    background-color: #ee800a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
  }

  .cta-button:hover {
    background-color: #09283f;
  }

  @media (max-width: 576px) {
    .subtext {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .cta-button {
      font-size: 16px;
      padding: 10px 20px;
      margin-top: 30px;
    }
  }
}
</style>
