<template>
  <div>
    <transition name="fade">
      <div v-if="isLoading" class="preloader">
        <div class="loader"></div>
      </div>
    </transition>

    <Header v-if="!isLoading"></Header>
    <router-view v-if="!isLoading" />

    <Footer v-if="!isLoading"></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
};
</script>

<style>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0% {
    box-shadow: 20px 0 #ee800a, -20px 0 #ee800a33;
    background: #ee800a;
  }
  33% {
    box-shadow: 20px 0 #ee800a, -20px 0 #ee800a33;
    background: #ee800a33;
  }
  66% {
    box-shadow: 20px 0 #ee800a33, -20px 0 #ee800a;
    background: #ee800a33;
  }
  100% {
    box-shadow: 20px 0 #ee800a33, -20px 0 #ee800a;
    background: #ee800a;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
